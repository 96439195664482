import React, { FC, useState } from 'react';
import { InputDialog } from 'wix-ui-tpa/InputDialog';
import { WixRicosEditor } from '@wix/ricos-editor';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { ModalProps } from '../../../../../types';
import {
  useInstance,
  useDefaultBIParams,
  useMember,
  useMemberHandlers,
} from '../../../../../contexts/widgets';
import settingsParams from '../../../settingsParams';
import { biSettings, plugins, toolbarSettings, visitorUploads } from './config';
import { classes, st, vars } from './AboutEditModal.st.css';

import '../RCE/ricos.global.scss';

interface AboutEditModalProps extends ModalProps {
  width: string;
  editorEvents: { publish: () => Promise<void> };
}

export const AboutEditModal: FC<AboutEditModalProps> = ({
  width,
  isOpen,
  editorEvents,
  onClose,
}) => {
  const settings = useSettings();
  const { defaultBIParams } = useDefaultBIParams();
  const { instance } = useInstance();
  const { isMobile, language } = useEnvironment();
  const { experiments } = useExperiments();
  const { t } = useTranslation();
  const { id, about } = useMember();
  const { publishMyAbout } = useMemberHandlers();
  const [aboutDraft, setAboutDraft] = useState(about);

  return (
    <InputDialog
      title={settings.get(settingsParams.aboutSectionTitle)}
      isOpen={isOpen}
      withoutDivider={true}
      primaryButton={{
        text: t('about-page.submit'),
        onClick: async () => {
          publishMyAbout(aboutDraft);
          editorEvents.publish();
          onClose();
        },
      }}
      secondaryButton={{ text: t('about-page.discard'), onClick: onClose }}
      onClose={onClose}
    >
      <div
        className={st(classes.root, { desktop: !isMobile })}
        style={{ [vars.rootWidth]: width }}
      >
        <WixRicosEditor
          placeholder={settings.get(settingsParams.aboutDescriptionPlaceholder)}
          content={aboutDraft.VERSION ? aboutDraft : undefined}
          plugins={plugins}
          toolbarSettings={toolbarSettings}
          instance={instance}
          wixExperiments={experiments}
          isMobile={isMobile}
          locale={language}
          biSettings={{
            ...biSettings,
            contentId: id ?? undefined,
            defaultParams: defaultBIParams,
          }}
          visitorUploads={visitorUploads}
          onChange={setAboutDraft}
        />
      </div>
    </InputDialog>
  );
};
