import React, { FC } from 'react';
import { Divider } from 'wix-ui-tpa/Divider';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { ControllerProps, DataHook } from '../../../types';
import Section from './common/Section';
import Header, { shouldRenderHeader } from './Header';
import Description, { useDescriptionRenderer } from './Description';
import Badges, { useBadgesRenderer } from './Badges';
import Overview, { useOverviewRenderer } from './Overview';
import BlogPosts, { useBlogPostsRendered } from './BlogPosts';
import EmptyState, {
  useEmptyStateRenderer,
  useBlockedEmptyStateRenderer,
} from './EmptyState';

import { classes, st } from './Widget.st.css';

const getInfoSectionContentState = (
  shouldDisplayOverviewAsSidebar: boolean,
  shouldRenderOverview: boolean,
) => ({
  fullWidth: !shouldDisplayOverviewAsSidebar || !shouldRenderOverview,
});

const getInfoSectionSidebarState = (
  shouldDisplayOverviewAsSidebar: boolean,
  shouldRenderInfoSectionContent: boolean,
) => ({
  fullWidth: !shouldRenderInfoSectionContent || !shouldDisplayOverviewAsSidebar,
});

export const Widget: FC<WidgetProps<ControllerProps>> = ({ host }) => {
  const { shouldRenderDescription } = useDescriptionRenderer();
  const { shouldRenderBadges } = useBadgesRenderer();
  const { shouldRenderOverview } = useOverviewRenderer();
  const { shouldRenderBlogPosts } = useBlogPostsRendered();
  const { shouldRenderEmptyState } = useEmptyStateRenderer({
    shouldRenderDescription,
    shouldRenderBadges,
    shouldRenderOverview,
    shouldRenderBlogPosts,
  });
  const { shouldRenderBlockedEmptyState } = useBlockedEmptyStateRenderer();
  const { isMobile, isRTL, isEditorX } = useEnvironment();
  const settings = useSettings();

  const shouldRenderInfoSectionContent =
    shouldRenderDescription || shouldRenderBadges;

  const shouldDisplayOverviewAsSidebar = host.dimensions.width
    ? host.dimensions.width > 750
    : true;

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
        responsive: !host.dimensions.width,
      })}
      dir={isRTL ? 'rtl' : 'ltr'}
      data-hook={DataHook.WidgetWrapper}
    >
      {shouldRenderHeader(isMobile, settings) && <Header />}
      {!isMobile && <Divider className={classes.divider} />}
      {shouldRenderBlockedEmptyState || shouldRenderEmptyState ? (
        <EmptyState isBlocked={shouldRenderBlockedEmptyState} />
      ) : (
        <>
          <div
            className={st(classes.infoSectionsWrapper, {
              childrenFullWidth: !shouldDisplayOverviewAsSidebar,
              responsive: isEditorX,
            })}
          >
            {shouldRenderInfoSectionContent && (
              <div
                className={st(
                  classes.infoSectionContent,
                  getInfoSectionContentState(
                    shouldDisplayOverviewAsSidebar,
                    shouldRenderOverview,
                  ),
                )}
              >
                {shouldRenderDescription && (
                  <Section>
                    <Description />
                  </Section>
                )}
                {shouldRenderBadges && (
                  <Section>
                    <Badges />
                  </Section>
                )}
              </div>
            )}
            {shouldRenderOverview && (
              <Section
                className={st(
                  classes.infoSectionSidebar,
                  getInfoSectionSidebarState(
                    shouldDisplayOverviewAsSidebar,
                    shouldRenderInfoSectionContent,
                  ),
                )}
              >
                <Overview />
              </Section>
            )}
          </div>
          {shouldRenderBlogPosts && (
            <Section>
              <BlogPosts />
            </Section>
          )}
        </>
      )}
    </div>
  );
};
