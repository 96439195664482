import { TOOLBARS } from 'wix-rich-content-editor-common';
import { pluginImage } from 'wix-rich-content-plugin-image';
import { pluginVideo } from 'wix-rich-content-plugin-video';
import { pluginDivider } from 'wix-rich-content-plugin-divider';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji';
import { pluginLink } from 'wix-rich-content-plugin-link';
import { ToolbarSettings } from '@wix/ricos-editor';

export const biSettings = {
  platform: 'Livesite' as const,
  consumer: 'members-about-ooi',
  usage: 'About (editor)',
};

export const plugins = [
  pluginImage(),
  pluginVideo(),
  pluginDivider(),
  pluginGiphy(),
  pluginEmoji(),
  pluginLink(),
];

export const toolbarSettings: ToolbarSettings = {
  getToolbarSettings: () => [
    {
      name: TOOLBARS.SIDE,
      shouldCreate: () => ({
        desktop: false,
        mobile: { android: false, ios: false },
      }),
    },
  ],
};

export const visitorUploads = { folderId: 'visitor-uploads' };
