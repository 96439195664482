import React, { FC, useRef } from 'react';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import { actionButtonClicked } from '@wix/bi-logger-members-app-uou/v2';

import { useMember, useCanEditMember } from '../../../../../contexts/widgets';
import { useModal } from '../../../../../contexts/modals';
import AboutEditModal from '../../common/AboutEditModal';
import Viewer from './Viewer';
import EmptyEditor from './EmptyEditor';
import { isAboutFilled } from './hooks';
import { classes, st } from './About.st.css';

export const About: FC = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const bi = useBi();
  const { isPreview, isMobile } = useEnvironment();
  const { canEditMember } = useCanEditMember();
  const { id, about } = useMember();
  const { showModal } = useModal();

  const shouldAllowEdit = canEditMember && (!isPreview || !isMobile);

  const openAboutEditModal = () => {
    const modalWidth = rootRef.current?.offsetWidth
      ? `${rootRef.current.offsetWidth ?? 0}px`
      : '100%';

    bi.report(
      actionButtonClicked({
        action_type: 'about_click',
        formOrigin: 'profile_page',
        member_chosen: id ?? undefined,
      }),
    );
    showModal(AboutEditModal, { width: modalWidth });
  };

  return (
    <div
      ref={rootRef}
      className={st(classes.root, { withHoverColor: shouldAllowEdit })}
    >
      {isAboutFilled(about) ? <Viewer /> : <EmptyEditor />}
      {shouldAllowEdit && (
        <div className={classes.overlay} onClick={openAboutEditModal} />
      )}
    </div>
  );
};
